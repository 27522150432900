import Preview from '~/components/elements/Preview'
import type { Assignment, Page_Flexcontent_Flex_Posts } from '~/graphql/types'
import { LosseContent as Content, LossePlaatjie as Image, LosseLink as Link } from '@ubo/losse-sjedel'
import { useState } from 'react'
import clsx from 'clsx'

export default function HighlightedAssignments({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const [assignments] = useState<Assignment[]>([
    ...(fields.highlightedassignments as Assignment[]),
    ...(fields.posts?.edges?.slice(0, 3 - (fields.highlightedassignments?.length || 0)).map((e) => e.node) as unknown as Assignment[])
  ])

  return (
    <section id="HighlightedAssignments">
      <div className="container section">
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-10 lg:gap-16 xl:gap-24">
          <div className="col-span-2 lg:col-span-1 flex items-start lg:pt-32">
            <div className="flex flex-col gap-7 md:gap-10 xl:gap-16">
              <Content className="content-sm">{fields.description}</Content>
              <Link to="/#Apply" className="flex flex-nowrap lg:flex-wrap xl:flex-nowrap items-center gap-6">
                {fields?.icon && (
                  <div className="flex items-center justify-center bg-sprout aspect-square min-w-[90px] xl:min-w-[125px] p-7 rounded-full">
                    <Image className="w-full" src={fields.icon} alt="video-icon" />
                  </div>
                )}
                <Content className={clsx(fields?.icon ? 'lg:children-p:text-lg' : 'lg:children-p:text-xl', "content")}>{fields.videodescription}</Content>
              </Link>
              <div className="flex lg:justify-start md:pt-0 pt-6 justify-center">
                <Link className="button-alt" to="/opdrachten/">
                  {fields.loadmoretext}
                </Link>
              </div>
            </div>
          </div>
          <div className={clsx(!fields.showvacancies && 'lg:block hidden', 'col-span-2')}>
            <div className="content">
              <h2>
                <span className="text-sprout">Uitgelichte</span>
                <br className="lg:hidden block" /> <span className="text-outrageous-orange">opdrachten</span>
              </h2>
            </div>

            <div className="flex flex-col gap-8 lg:gap-10 pt-6 lg:pt-10">
              {assignments?.map((post, index) => {
                const node = post as Assignment

                return <Preview type="assignment" key={index} item={node} readmoretext={fields.readmoretext} />
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

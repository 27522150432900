import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect } from 'react'
import HeaderMenu from './HeaderMenu'
import HeaderTop from './HeaderTop'
import { LosseLink as Link, useLosseLayout, useLoaderData, useLocation } from '@ubo/losse-sjedel'
import type { HeaderLoaderData } from './Header'

const HeaderMobile: React.FC = () => {
  const layout = useLosseLayout()
  const location = useLocation()
  const { header } = useLoaderData<HeaderLoaderData>()
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  useEffect(() => {
    setIsOpen(false)
  }, [location])

  return (
    <div className="block lg:hidden">
      <button
        type="button"
        onClick={() => {
          setIsOpen(!isOpen)
          layout.setScrollable(false)
        }}
        className="z-10 h-12 w-8 text-center"
        aria-label="Open menu"
      >
        <div className="mx-auto my-[5px] h-[4px] w-full bg-white" />
        <div className="mx-auto my-[5px] h-[4px] w-full bg-white" />
        <div className="mx-auto my-[5px] h-[4px] w-full bg-white" />
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ x: '-100%' }}
            animate={{ x: 0 }}
            exit={{ x: '-100%' }}
            transition={{ duration: 0.5 }}
            className="fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center bg-sprout"
          >
            <div
              className="absolute right-5 top-5 text-center"
              role="button"
              onClick={() => {
                setIsOpen(!isOpen)
                layout.setScrollable(true)
              }}
              aria-label="Close menu"
            >
              <div className="relative ml-4 h-8 w-8 before:absolute before:h-[33px] before:w-[4px] before:rotate-45 before:bg-outrageous-orange before:[content:''] after:absolute after:h-[33px] after:w-[4px] after:-rotate-45 after:bg-outrageous-orange after:[content:'']" />
            </div>
            <div className="m-0 pl-0 w-10/12 max-lg:-mt-12">
              <ul className="m-0 pl-0">
                <HeaderMenu />
              </ul>
              <div className="pt-4 sm:pt-10">
                <HeaderTop />
              </div>
              <div className="flex justify-center pt-10">
                <Link to={header.other?.whatsapp?.url} className="px-4 py-2 button-alt">
                  {header.other?.whatsapp?.title}
                </Link>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default HeaderMobile

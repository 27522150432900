/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-redeclare */
import type { Page_Flexcontent_Flex_Posts, FlexAssignment } from '~/graphql/types'
import Preview from '~/components/elements/Preview'
import { useSearchParams, LossePlaatjie as Image, LosseLink as Link } from '@ubo/losse-sjedel'
import React, { useEffect, useMemo, useState } from 'react'
import type { Feed } from '~/types/declarations'
import RangeFilter from '~/components/elements/RangeFilter'
import PostcodeFilter from '~/components/elements/PostcodeFilter'
import Content from '~/components/elements/Content'
import { motion, AnimatePresence } from 'framer-motion'
import OpenApply from '~/components/elements/OpenApply'
import useMediaQuery from '~/hooks/useMediaQuery'
import TermFilter from '~/components/elements/TermFilter'
import clsx from 'clsx'
import Paginate from 'react-paginate'

export type filterListType = {
  [key: string]: {
    [key: string]: string
  }
}

export type parsedAssignment = {
  node: FlexAssignment
  parsedData?: Feed.FeedObject
  returnValue?: boolean
}

const ITEMS_PER_PAGE = 10

export default function FlexAssignments({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [filtersShow, setFiltersShow] = useState(false)
  const [filters, setFilters] = useState<filterListType>({})
  const [assignments, setAssignments] = useState(JSON.parse(JSON.stringify(fields.posts?.edges)))
  const staticPosts: parsedAssignment[] = useMemo(() => JSON.parse(JSON.stringify(fields.posts?.edges)), [fields.posts])
  const mobile = useMediaQuery('(max-width: 1023px)')
  const [geoData, setGeoData] = useState<Record<string, number[]>>({})

  function handleFilter(type: string, e: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>) {
    if (e.currentTarget.value === '') {
      searchParams.delete(type)
    } else {
      searchParams.set(type, e.currentTarget.value)
    }

    setSearchParams(searchParams)
  }

  useEffect(() => {
    const filterList: filterListType = {
      branche: {},
      functie: {},
      opleidingsniveau: {}
    }

    staticPosts.forEach((node: any) => {
      const assignment = node?.node as FlexAssignment
      const data = JSON.parse(assignment.data?.data || '') as Feed.FeedObject
      node.parsedData = data

      // Branche
      data.Facets.Categories.forEach((category) => {
        if (!filterList.branche[category.UrlName]) {
          filterList.branche[category.UrlName] = category.Name
        }
      })

      // Functie
      data.Facets.FunctionTypes.forEach((functionType) => {
        if (!filterList.functie[functionType.UrlName]) {
          filterList.functie[functionType.UrlName] = functionType.Name
        }
      })

      // Opleidingsniveau
      data.Facets.EducationLevels.forEach((educationLevel) => {
        if (!filterList.opleidingsniveau[educationLevel.UrlName]) {
          filterList.opleidingsniveau[educationLevel.UrlName] = educationLevel.Name
        }
      })

      setFilters(filterList)
    })
  }, [staticPosts])

  useEffect(() => {
    async function getGeoData(value: string) {
      let latLongData
      if (!geoData[value]) {
        const response = await fetch(`https://api.pdok.nl/bzk/locatieserver/search/v3_1/free?fq=postcode:${value}`)
        const result = await response.json()
        latLongData = result.response.docs[0].centroide_ll.replace('POINT(', '').replace(')', '').split(' ')
        setGeoData({
          ...geoData,
          [value]: latLongData
        })
      } else {
        latLongData = geoData[value]
      }

      return latLongData
    }

    function calcCrow(lat1: number, lon1: number, lat2: number, lon2: number) {
      var R = 6371 // km
      var dLat = toRad(lat2 - lat1)
      var dLon = toRad(lon2 - lon1)
      var lat1 = toRad(lat1)
      var lat2 = toRad(lat2)

      var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
      var d = R * c
      return d
    }

    // Converts numeric degrees to radians
    function toRad(value: number) {
      return (value * Math.PI) / 180
    }

    async function help() {
      if (searchParams.toString().length > 0) {
        await Promise.all(
          staticPosts?.map(async (sp: parsedAssignment) => {
            let returnValue = true

            const mappableSearchParams: Array<{
              key: string
              value: string
            }> = []
            searchParams.forEach((value: string, key: string) => {
              mappableSearchParams.push({ key, value })
            })

            await Promise.all(
              mappableSearchParams?.map(async (value) => {
                if (returnValue === true) {
                  if (value.key === 'term') {
                    returnValue = false

                    if (sp.parsedData?.Facets.FunctionTypes) {
                      for (const functionTypes of sp.parsedData?.Facets?.FunctionTypes) {
                        if (functionTypes?.Name?.toLowerCase().includes(value.value.toLowerCase())) {
                          returnValue = true
                        }
                      }
                    }
                    // if (
                    //   sp.parsedData?.Descriptions?.AdditionalDescription?.toLowerCase().includes(value.value) ||
                    //   sp.parsedData?.Descriptions?.ApplicationProcedureDescription?.toLowerCase().includes(value.value) ||
                    //   sp.parsedData?.Descriptions?.ClientDescription?.toLowerCase().includes(value.value) ||
                    //   sp.parsedData?.Descriptions?.ClientDescription?.toLowerCase().includes(value.value) ||
                    //   sp.parsedData?.Descriptions?.FunctionDescription?.toLowerCase().includes(value.value) ||
                    //   sp.parsedData?.Descriptions?.OfferDescription?.toLowerCase().includes(value.value) ||
                    //   sp.parsedData?.Descriptions?.RequirementsDescription?.toLowerCase().includes(value.value) ||
                    //   sp.parsedData?.Descriptions?.Summary?.toLowerCase().includes(value.value)
                    // ) {
                    //   returnValue = true
                    // }
                  }
                  if (value.key === 'opleidingsniveau') {
                    if (sp.parsedData?.Facets.EducationLevels.findIndex((c) => c.UrlName === value.value) === -1) {
                      returnValue = false
                    }
                  } else if (value.key === 'functie') {
                    if (sp.parsedData?.Facets.FunctionTypes.findIndex((c) => c.UrlName === value.value) === -1) {
                      returnValue = false
                    }
                  } else if (value.key === 'branche') {
                    if (sp.parsedData?.Facets.Categories.findIndex((c) => c.UrlName === value.value) === -1) {
                      returnValue = false
                    }
                  } else if (value.key === 'minimum-uur') {
                    if ((sp.parsedData?.Employment.HoursPerWeekMax || 0) < parseInt(value.value)) {
                      returnValue = false
                    }
                  } else if (value.key === 'maximum-uur') {
                    if ((sp.parsedData?.Employment.HoursPerWeekMin || 0) > parseInt(value.value)) {
                      returnValue = false
                    }
                  } else if (value.key === 'postcode') {
                    const geoData = await getGeoData(value.value)
                    const distance = calcCrow(
                      parseFloat(geoData[1]),
                      parseFloat(geoData[0]),
                      sp.parsedData?.WorkLocation.Latitude || 0,
                      sp.parsedData?.WorkLocation.Longitude || 0
                    )

                    if (parseInt(searchParams.get('afstand') || '10') < distance) {
                      returnValue = false
                    }
                  } else if (value.key === 'afstand') {
                    // do nothing
                  }
                }
              })
            )

            sp.returnValue = returnValue
          })
        )

        const filteredPosts = staticPosts.filter((sp: parsedAssignment) => {
          return sp.returnValue
        })

        setAssignments(filteredPosts)
      } else {
        setAssignments(staticPosts)
      }
    }

    help()
  }, [searchParams, staticPosts])

  useEffect(() => {
    const { documentElement } = document

    if (filtersShow !== false) {
      documentElement.classList.add('overflow-hidden')
    } else {
      documentElement.classList.remove('overflow-hidden')
    }
  }, [filtersShow])

  const currentPage = Number(searchParams.get('pagina') || 0)
  const currentAssignments = assignments.slice(currentPage, currentPage + ITEMS_PER_PAGE)

  return (
    <section id="Assignments" className="overflow-hidden">
      <div className="bg-outrageous-orange lg:hidden block">
        <div className="container p-6 sm:py-11">
          <Content className="content-sm">{fields.description}</Content>
        </div>
      </div>
      <div className="container">
        <div className="grid grid-cols-2 lg:grid-cols-5 gap-4 lg:gap-10 2xl:gap-24">
          <div
            className={`fixed lg:overflow-visible lg:z-0 z-50 bottom-0 left-0 w-screen lg:w-[unset] ${filtersShow ? `h-screen bg-cold-morning overflow-scroll` : `h-auto`
              } lg:h-auto block lg:col-span-2 lg:relative transition-all duration-200`}
          >
            <div
              className={`bg-cold-morning lg:bg-mine-shaft lg:bg-opacity-5 py-4 lg:py-16 px-8 sm:px-10 lg:pl-0 lg:pr-10 2xl:pr-24 relative before:absolute before:top-0 before:h-full before:w-[2000px] before:-left-[2000px] before:bg-mine-shaft before:bg-opacity-5`}
            >
              <div
                className={`${filtersShow ? `fixed bg-cold-morning bottom-0 left-0 w-screen px-8 py-4 z-50` : ``
                  } flex xxs:flex-row flex-col items-center justify-between xxs:items-baseline`}
              >
                <div
                  onClick={() => {
                    if (mobile) {
                      setFiltersShow(!filtersShow)
                    }
                  }}
                  className={clsx(mobile && filtersShow && 'button-alt-outline w-[200px]', 'flex items-center gap-3')}
                >
                  <h2 className="font-nord lg:text-3xl font-medium leading-none lg:text-mine-shaft">
                    {mobile
                      ? `${!filtersShow ? `Open filters` : `Toon ${assignments.length} resulta${assignments.length === 1 ? `at` : `ten`}`}`
                      : 'Filters'}
                  </h2>
                  {!filtersShow && (
                    <svg
                      className="lg:hidden block"
                      width="19"
                      height="12"
                      viewBox="0 0 19 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path id="Vector 1" d="M2 10L9.5 3L17 10" stroke="#222222" strokeWidth="3" />
                    </svg>
                  )}
                </div>
                <div onClick={() => setSearchParams({})} className="underline text-base lg:text-xl cursor-pointer font-prompt font-light">
                  reset filters
                </div>
              </div>
              <div
                className={`${mobile ? `${filtersShow ? `flex` : `hidden`}` : `flex`
                  } relative pt-4 lg:pt-8 pb-32 2xl:py-10 flex-col gap-3 lg:gap-5`}
              >
                <svg
                  onClick={() => {
                    setFiltersShow(false)
                  }}
                  className="absolute lg:hidden block top-4 right-4 cursor-pointer"
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="fi-bs-cross" clipPath="url(#clip0_1580_442)">
                    <path
                      id="Vector"
                      d="M21.0535 1.80328L19.1968 -0.0534668L10.5002 8.64403L1.80353 -0.0534668L-0.0532227 1.80328L8.64428 10.4999L-0.0532227 19.1965L1.80353 21.0533L10.5002 12.3558L19.1968 21.0533L21.0535 19.1965L12.356 10.4999L21.0535 1.80328Z"
                      fill="#FF5C39"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1580_442">
                      <rect width="21" height="21" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <TermFilter vacancies={assignments} />

                {Object.keys(filters)?.map((filterType) => {
                  return (
                    <div key={filterType}>
                      <label className="block capitalize font-prompt text-base md:text-xl font-light pb-1 md:pb-3" htmlFor={filterType}>
                        {filterType}
                      </label>
                      <div className="relative">
                        <select
                          className="h-[51px] md:h-[59px] bg-white invalid:text-silver relative font-prompt text-base md:text-lg cursor-pointer font-light w-full px-4 2xl:px-6 block appearance-none focus:ring-2 focus-visible:outline-none focus:ring-offset-2 focus:ring-offset-white focus:ring-outrageous-orange"
                          name={filterType}
                          id={filterType}
                          required
                          onChange={(e) => handleFilter(filterType, e)}
                          value={searchParams.get(filterType) || ''}
                        >
                          <option value="" disabled hidden>
                            selecteer een {filterType.toLowerCase()}
                          </option>
                          {Object.keys(filters[filterType])?.map((key: string) => (
                            <option key={key} value={key}>
                              {filters[filterType][key]}
                            </option>
                          ))}
                        </select>
                        <svg
                          className="absolute z-10 top-0 bottom-0 right-4 2xl:right-6 my-auto"
                          width="26"
                          height="17"
                          viewBox="0 0 26 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M2 2L13 13L24 2" stroke="#FF5C39" strokeWidth="5" />
                        </svg>
                      </div>
                    </div>
                  )
                })}
                <PostcodeFilter />
                <RangeFilter />
              </div>
              <div className={`${mobile ? `${filtersShow ? `grid` : `hidden`}` : `grid`} hidden lg:grid grid-cols-11 lg:pt-10 gap-8`}>
                <div className="col-span-5 flex justify-center items-end overflow-hidden relative">
                  <Image
                    className="relative max-h-[200px] w-auto z-20"
                    maxWidth={500}
                    src={fields.person?.teammembercontent?.image}
                    alt="person-image"
                  />
                </div>
                <div className="col-span-6 flex justify-center flex-col">
                  <Content className="content-lg">{fields.person?.teammembercontent?.description}</Content>
                  <div className="font-bold font-prompt text-xl pb-1">{fields.person?.title}</div>
                  <Link
                    className="font-prompt text-lg font-light leading-relaxed hover:underline"
                    to={fields.person?.teammembercontent?.mail?.url || '/'}
                  >
                    {fields.person?.teammembercontent?.mail?.title}
                  </Link>
                  <Link
                    className="font-prompt text-lg font-light leading-relaxed hover:underline"
                    to={fields.person?.teammembercontent?.phone?.url || '/'}
                  >
                    {fields.person?.teammembercontent?.phone?.title}
                  </Link>
                </div>
              </div>
            </div>
            <div className="absolute -z-10 lg:block hidden rotate-[30deg] top-[0px] left-[672px] w-[600px] h-[3000px] bg-mine-shaft bg-opacity-5" />
          </div>
          <div className="col-span-2 lg:col-span-3 py-8 lg:py-14">
            <div>
              <AnimatePresence mode="wait">
                {currentAssignments.length === 0 ? (
                  <motion.div
                    key="found-nothing"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    className="flex flex-col gap-6 xl:gap-14"
                  >
                    <div className="text-center font-nord font-medium text-2xl">Geen vacatures gevonden</div>
                    <OpenApply fields={fields.openapplication?.openapply} />
                  </motion.div>
                ) : (
                  <motion.div
                    key="found-some-assignments"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    className="flex flex-col gap-6 xl:gap-14"
                  >
                    {currentAssignments?.map((post: any, index: number) => {
                      const node = post?.node as FlexAssignment

                      return (
                        <>
                          <Preview type="flexassignment" key={index} item={node} readmoretext={fields.readmoretext} />
                          {index === 3 && <OpenApply key="openapply" fields={fields.openapplication?.openapply} />}
                        </>
                      )
                    })}
                    {currentAssignments.length <= 2 && <OpenApply fields={fields.openapplication?.openapply} />}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            {currentAssignments.length > 0 && currentAssignments.length > ITEMS_PER_PAGE && (
              <div className="mt-16 flex justify-center">
                <Paginate
                  className="flex items-center gap-6 text-lg font-bold"
                  breakLabel="..."
                  nextLabel={
                    <svg
                      className="custom-swiper-next group w-[30px] lg:w-[40px] cursor-pointer"
                      width="52"
                      height="52"
                      viewBox="0 0 52 52"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle className="transition-all duration-200" cx="26" cy="26" r="26" fill="#FF5C39" />
                      <path d="M27.7027 25.7765L17 7H24.2973L35 25.7765L24.2973 45H17L27.7027 25.7765Z" fill="white" />
                    </svg>
                  }
                  onPageChange={(event) => {
                    const newOffset = (event.selected * ITEMS_PER_PAGE) % assignments.length
                    searchParams.set('pagina', newOffset.toString())
                    setSearchParams(searchParams)
                  }}
                  activeClassName="underline"
                  pageRangeDisplayed={3}
                  pageCount={Math.ceil(assignments.length / ITEMS_PER_PAGE)}
                  previousLabel={
                    <svg
                      className="custom-swiper-prev rotate-180 w-[30px] lg:w-[40px] group cursor-pointer"
                      width="52"
                      height="52"
                      viewBox="0 0 52 52"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle className="transition-all duration-200" cx="26" cy="26" r="26" fill="#FF5C39" />
                      <path d="M27.7027 25.7765L17 7H24.2973L35 25.7765L24.2973 45H17L27.7027 25.7765Z" fill="white" />
                    </svg>
                  }
                  renderOnZeroPageCount={null}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

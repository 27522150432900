import React from 'react'
import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import PostsHighlighted from './PostsHighlighted'
import FeedSlider from './FeedSlider'
import Branches from './Branches'
import Posts from './Posts'
import Testimonials from './Testimonials'
import Vacancies from './Vacancies'
import HighlightedAssignments from './HighlightedAssignments'
import HighlightedVacancies from './HighlightedVacancies'
import Assignments from './Assignments'
import Auto from './Auto'
import InternalVacancies from './InternalVacancies'
import FlippableBranches from './FlippableBranches'
import HighlightedFlexAssignments from './HighlightedFlexAssignments'
import FlexAssignments from './FlexAssignments'

const PostsShell: React.FC<LosseFlexShellProps> = ({ fields, index }) => {
  const types: LosseFlexShellType = {
    highlighted: PostsHighlighted,
    feedslider: FeedSlider,
    branches: Branches,
    flipbranches: FlippableBranches,
    blog: Posts,
    testimonials: Testimonials,
    vacancies: Vacancies,
    highlightedvacancies: HighlightedVacancies,
    assignments: Assignments,
    flexassignments: FlexAssignments,
    highlightedassignments: HighlightedAssignments,
    highlightedflexassignments: HighlightedFlexAssignments,
    auto: Auto,
    internalvacancies: InternalVacancies
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}

export default PostsShell

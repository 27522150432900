import { useSearchParams } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import { useRef, useState } from 'react'
import { DebounceInput } from 'react-debounce-input'
import Content from './Content'

export default function PostcodeFilter({ vacancies }: { vacancies: any }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchStart, setSearchStart] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState<any[]>([])
  const inputRef = useRef<DebounceInput>(null)

  // async function handleTerm(type: string, e: React.ChangeEvent<HTMLInputElement>) {
  //   if (e.target.value === '' || e.target.value === null || e.target.value === undefined) {
  //     searchParams.delete(type)
  //     setSearchParams(searchParams)
  //   } else {
  //     searchParams.set(type, e.target.value.toLowerCase())
  //     setSearchParams(searchParams)
  //   }
  // }

  async function search(e: React.ChangeEvent<HTMLInputElement>) {
    const result: any[] = []

    for (const vacancy of vacancies) {
      for (const functionType of vacancy.parsedData?.Facets?.FunctionTypes) {
        if (functionType?.Name?.toLowerCase().includes(e.target.value?.toLowerCase())) {
          if (!result.includes(functionType?.Name)) {
            result.push(functionType?.Name)
          }
        }
      }
    }

    if (e.target.value !== '') {
      setSearchStart(true)
    } else {
      searchParams.delete('term')
      setSearchParams(searchParams, {
        state: {
          scroll: false
        }
      })
      setSearchStart(false)
    }

    setSearchQuery(e.target.value)
    setSearchResults(result)
  }

  return (
    <>
      <section id="TermFilter" className={clsx(searchStart && `z-[100] relative`)}>
        <label className="block capitalize font-prompt text-base md:text-xl font-light pb-1 md:pb-3" htmlFor="term">
          Zoeken
        </label>
        <div className="relative">
          <DebounceInput
            ref={inputRef}
            className="h-[51px] md:h-[59px] mb-3 bg-white placeholder:text-silver relative font-prompt text-base md:text-lg cursor-pointer font-light w-full px-4 2xl:px-6 block appearance-none focus:ring-2 focus-visible:outline-none focus:ring-offset-2 rounded-none focus:ring-offset-white focus:ring-outrageous-orange"
            name="term"
            id="term"
            required
            minLength={0}
            debounceTimeout={0}
            placeholder="Zoek op trefwoord"
            onChange={(e) => search(e)}
            // onChange={(e) => handleTerm('term', e)}
            value={searchParams.get('term') || ''}
            // onFocus={() => setSearchStart(true)}
            // onBlur={() => setSearchStart(false)}
          />
          {searchStart && (
            <svg
              onClick={() => {
                // @ts-ignore
                if (inputRef.current) inputRef.current.state.value = ''
                searchParams.delete('term')
                setSearchParams(searchParams, {
                  state: {
                    scroll: false
                  }
                })
                setSearchStart(false)
              }}
              className="absolute top-0 bottom-0 my-auto right-4"
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="fi-bs-cross" clipPath="url(#clip0_1580_502)">
                <path
                  id="Vector"
                  d="M13.0329 1.11634L11.8835 -0.0330811L6.49984 5.35109L1.11621 -0.0330811L-0.0332031 1.11634L5.35096 6.49996L-0.0332031 11.8836L1.11621 13.033L6.49984 7.64884L11.8835 13.033L13.0329 11.8836L7.64871 6.49996L13.0329 1.11634Z"
                  fill="#FF5C39"
                />
              </g>
              <defs>
                <clipPath id="clip0_1580_502">
                  <rect width="13" height="13" fill="white" />
                </clipPath>
              </defs>
            </svg>
          )}
        </div>
      </section>
      <AnimatePresence>
        {searchStart && (
          <motion.div className="lg:absolute overflow-hidden fixed z-[90] lg:pt-[200px] pt-[150px] h-full lg:w-[calc(100%_+_20px)] w-full lg:bg-[#F4F4F4] bg-cold-morning top-0 lg:-left-[10px] left-0">
            <div className="container">
              <div className="flex lg:px-0 px-4 flex-col lg:gap-5 gap-7">
                {searchResults.map((item, index) => (
                  <div
                    onClick={() => {
                      setSearchStart(false)
                      // @ts-ignore
                      if (inputRef.current) inputRef.current.state.value = item
                      searchParams.set('term', item)
                      setSearchParams(searchParams, {
                        state: {
                          scroll: false
                        }
                      })
                    }}
                    className="flex group cursor-pointer items-center gap-4"
                    key={index}
                  >
                    <svg className="min-w-[13px]" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="fi-bs-search" clipPath="url(#clip0_1580_535)">
                        <path
                          id="Vector"
                          d="M10.2378 9.0874C11.1026 7.93171 11.4975 6.49133 11.343 5.05619C11.1884 3.62106 10.496 2.29773 9.40506 1.35259C8.3141 0.407452 6.9056 -0.089321 5.46309 -0.0377312C4.02059 0.0138586 2.65117 0.609981 1.63052 1.63064C0.609859 2.6513 0.0137365 4.02071 -0.0378533 5.46321C-0.0894431 6.90572 0.40733 8.31422 1.35247 9.40518C2.29761 10.4961 3.62093 11.1886 5.05607 11.3431C6.4912 11.4976 7.93159 11.1027 9.08728 10.2379L11.8498 13.0004L13.0003 11.8521L10.2378 9.0874ZM5.68778 9.7504C4.8843 9.7504 4.09885 9.51214 3.43078 9.06575C2.7627 8.61936 2.242 7.98488 1.93452 7.24256C1.62704 6.50023 1.54659 5.6834 1.70334 4.89535C1.8601 4.1073 2.24701 3.38343 2.81516 2.81528C3.38331 2.24713 4.10718 1.86022 4.89523 1.70346C5.68328 1.54671 6.50011 1.62716 7.24243 1.93464C7.98476 2.24213 8.61924 2.76283 9.06563 3.4309C9.51202 4.09897 9.75028 4.88442 9.75028 5.6879C9.74928 6.76504 9.32094 7.79777 8.5593 8.55942C7.79765 9.32107 6.76492 9.7494 5.68778 9.7504Z"
                          fill="#222222"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1580_535">
                          <rect width="13" height="13" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <Content className="mb-0 p-1 first-letter:uppercase group-hover:underline text-sm lg:text-base font-prompt font-light text-mine-shaft">
                      {item
                        ?.toLowerCase()
                        .replace(searchQuery.toLowerCase(), `<strong className="font-bold">${searchQuery.toLowerCase()}</strong>`)}
                    </Content>
                  </div>
                ))}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

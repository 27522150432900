import React from 'react'
import { useLoaderData, LosseLink as Link } from '@ubo/losse-sjedel'
import type { HeaderLoaderData } from './HeaderMenu'
import useMediaQuery from '~/hooks/useMediaQuery'
import { AnimatePresence, motion } from 'framer-motion'
import HeaderSubMenu from './HeaderSubmenu'
import clsx from 'clsx'

const HeaderTop: React.FC = () => {
  const { header } = useLoaderData<HeaderLoaderData>()
  const [open, setOpen] = React.useState(-1)
  const isDesktop = useMediaQuery('(min-width: 1024px)')

  return (
    <ul className="mb-0 h-full w-full list-none lg:items-center justify-center gap-2 md:gap-6 xl:gap-10 xl:gap-20 lg:flex-row flex-col flex">
      {header?.topmenu?.map((l, index: number) => (
        <motion.li onHoverStart={() => isDesktop && setOpen(index)}
          onHoverEnd={() => isDesktop && setOpen(-1)}
          className="relative max-lg:my-2" key={index}>
          <div className="flex items-center">
            <Link
              className="hover:underline font-medium font-prompt text-lg md:text-sm xl:text-base lg:font-nord inline-flex items-center"
              to={l?.link?.url}

            >
              <span>{l?.link?.title}</span>
              <svg className={clsx(l?.submenu?.length > 0 && 'hidden', 'lg:hidden ml-2')} width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 7L4 4L1 1" stroke="#222222" />
              </svg>
            </Link>
            {l?.submenu && (
              <div onClick={open === index ? () => setOpen(-1) : () => setOpen(index)} className="ml-2 lg:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style={
                    open === index
                      ? {
                        transform: 'rotate(180deg)'
                      }
                      : {
                        transform: 'rotate(0deg)'
                      }
                  }
                  width={15}
                  height={15}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#222222"
                  strokeWidth={3}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="transition-all"
                >
                  <polyline points="6 9 12 15 18 9" />
                </svg>
              </div>
            )}
          </div>
          {l?.submenu && open === index && (
            <AnimatePresence>
              <motion.ul
                className="relative w-fit overflow-hidden lg:absolute"
                initial={{ height: 0 }}
                animate={{ height: 'fit-content' }}
                exit={{ height: 0 }}
              >
                <div className="lg:pt-3">
                  <div className="py-1 pr-2 pl-3 lg:rounded-b-[15px] bg-sprout lg:px-2 lg:py-3 lg:shadow-2xl">
                    {l?.submenu && l?.submenu.map((f, index: number) => (
                      <li className="list-none" key={index}>
                        <Link
                          className="text-mine-shaft block px-3 py-1 lg:rounded-lg lg:hover:bg-outrageous-orange lg:hover:text-white whitespace-nowrap font-nord font-medium lg:text-primary"
                          to={f?.link?.url || '/'}
                        >
                          {f?.link?.title}
                        </Link>
                      </li>
                    ))}
                  </div>
                </div>
              </motion.ul>
            </AnimatePresence>
          )}
        </motion.li>
      ))}
    </ul>
  )
}

export default HeaderTop

import { AnimatePresence, motion } from "framer-motion";
import { useRef } from "react";
import { LossePlaatjie as Image, LosseContent as Content, LosseLink } from '@ubo/losse-sjedel'
import type { Page_Flexcontent_Flex_Misc } from "~/graphql/types";

export default function MVO({ fields }: { fields: Page_Flexcontent_Flex_Misc }) {
  const element = useRef<HTMLDivElement>(null)

  return (
    <section ref={element} id="MVO">
      <div className="container py-10 md:py-16 lg:py-24">
        <div className="flex justify-center flex-wrap lg:grid lg:grid-cols-[repeat(17,_minmax(0,_1fr))] xl:px-8">
          <div className="col-span-10 bg-outrageous-orange aspect-video p-10 lg:px-24 lg:h-[579px] w-full flex justify-center items-center">
            <AnimatePresence mode="wait">
              <motion.div
                id='content-testi-0'
                key='content-testi-0'
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 20, opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Content className="content-lg children-h2:text-white lg:children-h2:text-4xl children-h2:mb-6 lg:children-p:text-xl children-span:text-sprout">{fields.description}</Content>
                <LosseLink to={fields?.button?.url} className="button mt-4" target={fields?.button?.target || "_blank"}>{fields?.button?.title}</LosseLink>
              </motion.div>
            </AnimatePresence>
          </div>
          <div className="col-span-7 relative flex justify-center items-center">
            <div className="h-[280px] xs:h-[310px] s:h-[380px] sm:h-[440px] md:h-[400px]  overflow-hidden relative">
              <AnimatePresence>
                <motion.div
                  id='content-testi-0'
                  key='image-testi-0'
                  initial={{ x: '100%' }}
                  animate={{ x: 0 }}
                  exit={{ x: '-100%' }}
                  transition={{ duration: 0.5 }}
                  className="h-full w-full"
                >
                  <Image
                    maxwidth={2000}
                    className="h-full object-cover"
                    src={fields.image}
                    alt="testimonial-image"
                  />
                </motion.div>
              </AnimatePresence>

            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Teammembers, Teammembers } from '~/graphql/types'

export default function TeammembersOverview({ fields }: { fields: Page_Flexcontent_Flex_Teammembers }) {
  return (
    <section data-component="TeammembersOverview" className="section">
      <div className="container">
        <div className="flex flex-col gap-14 lg:gap-20">
          <div className="max-w-screen-lg mx-auto">
            <Content className="content">{fields.description}</Content>
          </div>
          {/* <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:gap-20 lg:gap-14 gap-11">
            {fields.teammembers?.edges.map((teammember, index) => {
              const member = teammember.node as Teammembers

              return (
                <div className="grid grid-cols-2 shadow-feed" key={index}>
                  <LossePlaatjie className="h-full object-cover" maxwidth={500} src={member.teammembercontent?.image} />
                  <div className="px-5 py-3 sm:p-10 md:p-4 lg:p-8 xl:px-7 xl:py-4 flex flex-col justify-between gap-3">
                    <div>
                      <h3 className="text-sprout text-lg sm:text-3xl md:text-2xl font-prompt font-bold">{member.title}</h3>
                      <h4 className="text-outrageous-orange text-base sm:text-2xl md:text-xl font-prompt font-bold xl:font-semibold">
                        {member.teammembercontent?.function}
                      </h4>
                    </div>
                    <div className="flex flex-col gap-1">
                      <div className="text-sm sm:text-lg md:text-base hover:underline hover:text-outrageous-orange lg:text-base text-mine-shaft font-prompt">
                        <a href={member.teammembercontent?.mail?.url || '/'}>{member.teammembercontent?.mail?.title}</a>
                      </div>
                      <div className="text-sm sm:text-lg md:text-base hover:underline hover:text-outrageous-orange lg:text-base text-mine-shaft font-prompt">
                        <a href={member.teammembercontent?.phone?.url || '/'}>{member.teammembercontent?.phone?.title}</a>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div> */}
        </div>
      </div>
    </section>
  )
}

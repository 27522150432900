import { LosseFlex } from '@ubo/losse-sjedel'
import type { Page_Flexcontent } from '~/graphql/types'

// Flex elements
import BannerShell from './Banner/BannerShell'
import TextShell from './Text/TextShell'
import PostsShell from './Posts/PostsShell'
import FormShell from './Form/FormShell'
import CtaShell from './CTA/CtaShell'
import MiscShell from './Misc/MiscShell'
import Fragment from './Fragment'
import TeammembersShell from './Teammembers/TeammembersShell'
import Reviews from './Reviews'

interface FlexContentHandlerProps {
  flex: Page_Flexcontent['flex']
  prefix: string
}

export default function FlexContentHandler({ flex, prefix = 'page_Flexcontent' }: FlexContentHandlerProps) {
  const flexers = {
    [`${prefix}_Flex_Banner`]: BannerShell,
    [`${prefix}_Flex_Text`]: TextShell,
    [`${prefix}_Flex_Posts`]: PostsShell,
    [`${prefix}_Flex_Form`]: FormShell,
    [`${prefix}_Flex_Cta`]: CtaShell,
    [`${prefix}_Flex_Misc`]: MiscShell,
    [`${prefix}_Flex_Teammembers`]: TeammembersShell,
    [`${prefix}_Flex_Fragment`]: Fragment,
    [`${prefix}_Flex_Reviews`]: Reviews
  }

  // @ts-ignore
  return <LosseFlex flex={flex} flexers={flexers} />
}

import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import { LossePlaatjie as Image } from '@ubo/losse-sjedel'

export default function BannerTitle({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section id="BannerTitle" className="relative">
      <div className="max-w-[2000px] mx-auto">
        <Image
          maxwidth={2000}
          className="max-h-[400px] w-full h-full max-md:h-[550px] md:max-h-[510px] 2xl:max-h-[612px] object-cover"
          src={fields.image}
          alt="banner-images-image-1"
        />
        <div className="container absolute left-0 right-0 bottom-12 lg:bottom-32">
          <div className="relative flex items-center justify-center">
            <Content className="content children-headings:text-shadow children-headings:text-white">
              {fields.description}
            </Content>
          </div>
        </div>
      </div>
    </section>
  )
}

import type { Maybe, InternalVacancy } from '~/graphql/types'
import { LosseLink as Link, LossePlaatjie as Image } from '@ubo/losse-sjedel'
import { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import type { Feed } from '~/types/declarations'
import Content from '~/components/elements/Content'

export default function InternalVacancyPreview({
  item,
  readmoretext = 'Bekijk hele vacature',
  key
}: {
  item: InternalVacancy
  readmoretext?: Maybe<string> | undefined
  key: number
}) {
  const data = JSON.parse(item.data?.data || '') as Feed.FeedObject
  const [leven, setLeven] = useState<boolean>(key % 2 === 0 ? true : false)

  return (
    <div id="Preview" className="bg-white relative shadow-feed flex flex-col gap-5 lg:gap-10 pt-6 lg:pt-9 pb-5 lg:pb-14 px-5 lg:px-14">
      <div className="flex gap-4 justify-between items-center">
        <div className="flex gap-4 lg:gap-9">
          <div className="bg-sprout relative px-4 pb-5">
            <div className="bg-sprout absolute h-6 lg:h-9 bottom-full left-0 w-full" />
            {item.data?.branche?.recap?.image ? (
              <Image className="w-[63px] h-[63px] max-w-[unset]" src={item.data.branche.recap.image} alt="branche-icoon" />
            ) : (
              <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_645_116)">
                  <path d="M33.2305 40.1502L26.7305 53.1602H34.3205L50.4405 20.9302H42.8405L33.2305 40.1502Z" fill="white" />
                  <path d="M33.2302 20.9302H26.1602V40.1502H33.2302V20.9302Z" fill="white" />
                  <path
                    d="M34.9 0C15.66 0 0 15.66 0 34.9C0 54.14 15.66 69.8 34.9 69.8C54.14 69.8 69.8 54.14 69.8 34.9C69.8 15.66 54.14 0 34.9 0ZM34.9 62.8C19.52 62.8 7 50.28 7 34.9C7 19.52 19.52 7 34.9 7C50.28 7 62.8 19.52 62.8 34.9C62.8 50.28 50.28 62.8 34.9 62.8Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_645_116">
                    <rect width="69.8" height="69.8" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            )}
          </div>
          <h2 className="font-nord font-medium text-xl md:text-2xl lg:text-3xl">{item.title}</h2>
        </div>

        <Link
          className="button-alt-outline px-6 py-3 lg:relative absolute bottom-5 lg:bottom-0 right-5 lg:right-0 whitespace-nowrap"
          to={item.uri}
        >
          {readmoretext}
        </Link>
      </div>
      <div className="flex md:flex-row flex-col gap-2 md:gap-10 lg:gap-20">
        {data.Id ? (
          <>
            {/* LOCATIE */}
            {data?.WorkLocation?.City && (
              <div className="col-span-1 flex items-center gap-2 font-prompt font-light text-base lg:text-lg">
                <svg className="w-[25px]" width="27" height="32" viewBox="0 0 27 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M23.0459 3.92928C20.4959 1.39523 17.1058 0 13.5 0C9.89423 0 6.50408 1.39523 3.95406 3.92928C1.40403 6.46332 0 9.83222 0 13.4154C0 16.3973 1.91827 20.1225 5.70152 24.4869C8.72763 27.9786 11.9155 30.6589 12.2644 30.9491L13.4982 32L14.7387 30.9569C15.3244 30.4651 18.3947 27.8408 21.2997 24.4875C25.0823 20.1219 27 16.3967 27 13.4154C27 9.83222 25.596 6.46332 23.0459 3.92928ZM23.1622 13.4154C23.1622 15.1477 21.8957 17.6468 19.5001 20.6425C17.2905 23.4059 14.7659 25.8081 13.4994 26.9584C12.2323 25.8075 9.70767 23.4065 7.49865 20.6449C5.10308 17.6498 3.83655 15.1501 3.83655 13.416C3.83958 10.8525 4.84566 8.4418 6.67005 6.62945C8.49444 4.81649 10.9191 3.81672 13.4988 3.81371C16.0785 3.81672 18.5037 4.81649 20.3275 6.62945C22.1519 8.4424 23.158 10.8525 23.161 13.4154H23.1622Z"
                    fill="#D0DEBB"
                  />
                  <path
                    d="M13.5 7.78873C10.3782 7.78873 7.83783 10.3125 7.83783 13.4154C7.83783 16.5182 10.3776 19.0421 13.5 19.0421C16.6224 19.0421 19.1621 16.5182 19.1621 13.4154C19.1621 10.3125 16.6224 7.78873 13.5 7.78873ZM15.5588 13.4154C15.5588 14.5434 14.6351 15.4613 13.5 15.4613C12.3649 15.4613 11.4412 14.5434 11.4412 13.4154C11.4412 12.2874 12.3649 11.3695 13.5 11.3695C14.6351 11.3695 15.5588 12.2874 15.5588 13.4154Z"
                    fill="#D0DEBB"
                  />
                </svg>
                {data.WorkLocation.City}
              </div>
            )}
            {/* UREN */}
            {data?.Employment && (
              <div className="col-span-1 flex items-center gap-2 font-prompt font-light text-base lg:text-lg">
                <svg className="w-[25px]" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16 0C7.16375 0 0 7.16375 0 16C0 24.8363 7.16375 32 16 32C24.8325 31.9906 31.9906 24.8325 32 16C32 7.16375 24.8363 0 16 0ZM16 28C9.3725 28 4 22.6275 4 16C4 9.3725 9.3725 4 16 4C22.6244 4.0075 27.9925 9.37563 28 16C28 22.6275 22.6275 28 16 28Z"
                    fill="#D0DEBB"
                  />
                  <path d="M13.9998 14.74L9.10547 17.8057L11.228 21.1944L17.9998 16.9532V8.36255H13.9998V14.74Z" fill="#D0DEBB" />
                </svg>
                {`${data.Employment.HoursPerWeekMin} - ${data.Employment.HoursPerWeekMax} uur`}
              </div>
            )}
            {/* SALARIS */}
            {data?.Salary && data?.Salary?.SalaryMax !== 0 && data?.Salary?.SalaryMin !== 0 && (
              <div className="col-span-1 flex items-center gap-2 font-prompt font-light text-base lg:text-lg">
                <svg className="w-[25px]" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.8234 11.1937C16.114 10.7581 17.4971 10.8506 18.7177 11.4556C19.3671 11.7768 19.9296 12.2237 20.3909 12.7831L20.5896 13.0243L22.4652 11.4762L22.2665 11.2349C20.9871 9.68244 19.1796 8.72182 17.1771 8.52869C15.1752 8.33557 13.2171 8.93432 11.6646 10.2137C10.6652 11.0374 9.89586 12.1049 9.42961 13.3106H7.70898V15.7418H8.92086C8.91586 15.8362 8.91273 15.9324 8.91273 16.0312C8.91273 16.1299 8.91586 16.2268 8.92086 16.3206H7.70898V18.7518H9.42961C10.5752 21.7206 13.4271 23.5699 16.4621 23.5693C17.2915 23.5693 18.1352 23.4312 18.9609 23.1399C20.2521 22.6843 21.3952 21.8849 22.2665 20.8281L22.4652 20.5868L20.5896 19.0387L20.3909 19.2806C19.4196 20.4612 17.9871 21.1374 16.4584 21.1374H16.4515C14.6746 21.1349 13.0646 20.2331 12.1365 18.7518H17.9677V16.3206H11.3534C11.3415 16.1274 11.3415 15.9343 11.3534 15.7412H17.9677V13.3099H12.1359C12.7584 12.3156 13.6965 11.5724 14.8227 11.1924L14.8234 11.1937Z"
                    fill="#D0DEBB"
                  />
                  <path
                    d="M16 0C7.16375 0 0 7.16375 0 16C0 24.8363 7.16375 32 16 32C24.8325 31.9906 31.9906 24.8325 32 16C32 7.16375 24.8363 0 16 0ZM16 28C9.3725 28 4 22.6275 4 16C4 9.3725 9.3725 4 16 4C22.6244 4.0075 27.9925 9.37563 28 16C28 22.6275 22.6275 28 16 28Z"
                    fill="#D0DEBB"
                  />
                </svg>
                {data.Salary.Description}
                {/* {`${data?.Salary?.SalaryMin} - ${data?.Salary?.SalaryMax}`} */}
              </div>
            )}
          </>
        ) : (
          <>
            <div className="col-span-1">
              <div className="flex animate-pulse flex-row items-center h-full space-x-5">
                <div className="w-32 bg-sprout bg-opacity-70 h-8 rounded-md "></div>
              </div>
            </div>
            <div className="col-span-1">
              <div className="flex animate-pulse flex-row items-center h-full space-x-5">
                <div className="w-32 bg-sprout bg-opacity-70 h-8 rounded-md "></div>
              </div>
            </div>
            <div className="col-span-1">
              <div className="flex animate-pulse flex-row items-center h-full space-x-5">
                <div className="w-32 bg-sprout bg-opacity-70 h-8 rounded-md "></div>
              </div>
            </div>
            <div className="col-span-1">
              <div className="flex animate-pulse flex-row items-center h-full space-x-5">
                <div className="w-32 bg-sprout bg-opacity-70 h-8 rounded-md "></div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="flex lg:flex-row flex-col-reverse lg:items-center gap-2 pb-20 lg:pb-0 lg:gap-16 md:pt-4 lg:pt-10">
        <div className="flex justify-center">
          <label>
            <div className="relative block cursor-pointer group">
              <input
                type="checkbox"
                id="jouwwerkjouwleven"
                checked={leven}
                className="sr-only"
                onChange={(e) => {
                  setLeven(e.target.checked)
                }}
              />
              <div className={`block ${leven ? `bg-outrageous-orange` : `bg-sprout`} w-[254px] h-[138px] rounded-full`} />
              <motion.div
                initial={{ left: 11 }}
                animate={leven ? { left: 'calc(100% - 128px)' } : { left: 11 }}
                className={`absolute flex justify-center items-center top-[10px] w-[117px] h-[117px] rounded-full bg-white transition-all duration-200`}
              >
                {leven ? (
                  <div className="font-nord font-medium text-outrageous-orange">
                    Jouw
                    <br /> leven
                  </div>
                ) : (
                  <div className="font-nord font-medium text-outrageous-orange">
                    Jouw
                    <br /> werk
                  </div>
                )}
              </motion.div>
            </div>
          </label>
        </div>
        <div className="relative overflow-hidden">
          <AnimatePresence mode="wait">
            {leven ? (
              <motion.div
                key="leven-text"
                id="leven-text"
                initial={{ y: '-100%' }}
                animate={{ y: 0 }}
                exit={{ y: '100%' }}
                transition={{ duration: 0.3 }}
              >
                <Content className="line-clamp-5">{data.Descriptions.OfferDescription}</Content>
              </motion.div>
            ) : (
              <motion.div
                key="werk-text"
                id="werk-text"
                initial={{ y: '-100%' }}
                animate={{ y: 0 }}
                exit={{ y: '100%' }}
                transition={{ duration: 0.3 }}
              >
                <Content className="children-ul:hidden line-clamp-5">{data.Descriptions.FunctionDescription}</Content>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  )
}
